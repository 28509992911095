import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function AccountingHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage: "url(" + require("assets/img/Accountant.jpg") + ")"
        }}
      >
        <div className="filter filter-primary" />
        <div className="content-center">
          <Container>
            <h1 style={{ fontWeight: "600" }}>ACCOUNTING SERVICES</h1>
            <h3 style={{ fontWeight: "500" }}>
              Unlock the potential of your business with Nimble IT Consulting' IT-driven
              accounting expertise.
            </h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AccountingHeader;
