import { colors } from "assets/colors";
import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const CustomCard = ({ title, description }) => {
  return (
    <Col lg={6} md={6} xs={12}>
      <div>
        <Card
          className="card-just-text"
          data-background="color"
          data-radius="none"
          style={{
            borderBottomRightRadius: "4rem",
            borderTopLeftRadius: "4rem"
          }}
        >
          <CardTitle
            className="custom-card-title"
            style={{
              backgroundColor: "black",
              borderTopLeftRadius: "4rem",
              borderBottom: `3px solid ${colors.primary.main}`,
              padding: "0.5rem",
              paddingTop: "0.8rem",
              paddingBottom: "0.8rem",
              fontSize: "1rem",
              paddingLeft: "3rem",
              fontWeight: "600"
            }}
          >
            {title}
          </CardTitle>
          <CardBody>
            <p className="card-description" style={{textAlign: "left"}}>{description}</p>
          </CardBody>
        </Card>
        {/* end card */}
      </div>
    </Col>
  );
};

export default CustomCard;
