import React, { useState } from "react";
// react plugin used to create google maps
// reactstrap components
import { Button, Form, Input, Container, Row, Col, CardBody } from "reactstrap";
import FooterBlack from "components/Footers/FooterBlack";
import ColorNavbar from "components/Navbars/ColorNavbar";
import { colors } from "assets/colors";
import axios from "axios";
import { API } from "constant";
import { CONTACT_US } from "constant";
import CustomToast from "components/custom-toast";

function ContactUs() {
  document.documentElement.classList.remove("nav-open");
  const [toast, setToast] = useState({
    message: "",
    open: false,
    type: "error"
  });
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: ""
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const InputFields = [
    {
      name: "firstName",
      label: "First Name *",
      type: "string",
      md: 6
    },
    {
      name: "lastName",
      label: "Last Name *",
      type: "string",
      md: 6
    },
    {
      name: "email",
      label: "Email *",
      type: "email",
      md: 12
    },
    {
      name: "subject",
      label: "Subject",
      type: "string",
      md: 12
    },
    {
      name: "message",
      label: "Message *",
      type: "textarea",
      rows: 7,
      md: 12
    }
  ];
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      !fields.firstName ||
      !fields.lastName ||
      !fields.message ||
      !fields.email
    ) {
      setError("Please complete all required fields");
    } else {
      setError("");
      if (!/\S+@\S+\.\S+/.test(fields.email)) {
        return setError("Please enter a valid email address");
      } else {
        setError("");
        try {
          const response = await axios.post(`${API}${CONTACT_US}`, fields);
          if (response) {
            setFields({
              firstName: "",
              lastName: "",
              message: "",
              subject: "",
              email: ""
            });
            setToast({
              open: true,
              type: "success",
              message: response.data.title
            });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };
  return (
    <>
      <ColorNavbar />
      <div className="main">
        <div className="section section-white">
          <Col>
            <Container>
              <Row style={{ marginRight: "0px" }}>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title" style={{ fontWeight: "600" }}>
                    CONTACT US
                  </h2>
                  <p className="mb-3">
                    Collaboratively administrate empowered markets via
                    plug-and-play networks. Dynamically procrastinate B2C users
                    after installed base benefits.
                  </p>
                  {error && (
                    <p style={{ fontWeight: "600", color: "red" }}>{error}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  className="ml-auto mr-auto text-center"
                  md="6"
                  style={{
                    border: `1px solid ${colors.primary.main}`,
                    borderRadius: "0.8rem",
                    // backgroundColor: "black",
                    padding: "2rem"
                  }}
                >
                  <Form className="contact">
                    <Row>
                      {InputFields.map((item) => {
                        return (
                          <Col md={item.md}>
                            <Input
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  [item.name]: e.target.value
                                });
                              }}
                              placeholder={item.label}
                              value={fields[item.name]}
                              type={item.type}
                              rows={item.rows}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <label for="form-field-acc" style={{ padding: "0.6rem" }}>
                      I have read the{" "}
                      <a
                        href="/privacy-policy"
                        style={{
                          color: colors.primary.main,
                          textDecoration: "underline"
                        }}
                      >
                        privacy policy
                      </a>{" "}
                      and consent to the given information being used by Nimble
                      IT Consulting. to contact me about services they offer.
                    </label>
                    <Row>
                      <Col className="ml-auto mr-auto" md="6" sm="5" xs="7">
                        <Button
                          block
                          disabled={isLoading}
                          onClick={onSubmitHandler}
                          style={{
                            backgroundColor: colors.primary.main,
                            border: "none",
                            width: "100%"
                          }}
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Col>
        </div>
      </div>
      <CustomToast
        open={toast.open}
        message={toast.message}
        type={toast.type}
        setOpen={setToast}
      />
      <FooterBlack />
    </>
  );
}

export default ContactUs;
