import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function SectionSummary() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-layout-11" />
                </div>
                <div className="description">
                  <h4 className="info-title">DevOps</h4>
                  <p style={{textAlign: "justify"}}>
                    A world where quick turn arounds are to be made with time
                    crisis, DevOps has become the feasible solution for Software
                    Project to be successful. We leverage our years of
                    experience with the DevOps best practices and in fact we
                    advocate it. Our experienced DevOps Consultants can help you
                    choose the correct tools for Infrastructures as code ( IAC
                    ), Configuration management and the monitoring tools which
                    meets your requirement ​
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-tile-56" />
                </div>
                <div className="description">
                  <h4 className="info-title">Cloud Solutions</h4>
                  <p style={{textAlign: "justify"}}>
                    Regardless of where you stand in the road map to Cloud, our
                    team of cloud specialists can help you. Be it Migration from
                    inhouse to Cloud platform, improving your existing cloud
                    infrastructure or mitigating existing and underlying issues
                    by reviewing it. We are with you on this journey as your
                    companion in architecting and advising on the best possible
                    solutions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-paper" />
                </div>
                <div className="description">
                  <h4 className="info-title">Digital</h4>
                  <p style={{textAlign: "justify"}}>
                    You believe in your core business establishment and
                    processes but you are yet to be in digital space then our
                    consulting services can help you define and execute highly
                    strategic and complex digital transformation programs from
                    ideation to vision and design to delivery.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;
