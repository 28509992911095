import { colors } from "assets/colors";
import ColorNavbar from "components/Navbars/ColorNavbar";
import React from "react";
import { Col, Container, Row } from "reactstrap";

const PrivacyPolicy = () => {
  const personalData = [
    "You request a proposal for our services.",
    " You or your employer or our clients engage us for services, and during the provision of those services, we may collect your personal data to fulfill contractual obligations.",
    "  You reach out to us via email, telephone, post, website portal, or social media, such as when you have queries about our services.",
    "  Information is obtained from third parties and/or publicly available sources, such as your employer or the Jersey Financial Services Commission.",
    " Data is sourced using appropriate online tools to fulfill our regulatory due diligence obligations."
  ];
  const typesOfInformation = [
    "Personal details like your name and address.",
    "Records of our interactions concerning the provision or proposed provision of services.",
    "Details of services received from us.",
    "Correspondence and communications.",
    "Information regarding any complaints or inquiries made by you.",
    "Data received from other sources, such as publicly available information or information provided by your employer or our clients.",
    "Information derived from online checks conducted for regulatory due diligence."
  ];
  const yourData = [
    "Fulfilling obligations arising from agreements between you, your employer, or our clients, and us, typically related to service provision.",
    "Providing information about our services, events, and activities that you have requested or may find of interest.",
    "Seeking feedback on the services we provide.",
    "Notifying you of any changes to our services."
  ];
  return (
    <>
      <ColorNavbar />
      <div className="main">
        <div className="section section-white mt-5 privacy-policy">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title" style={{ fontWeight: "600" }}>
                  Privacy Policy
                </h1>
              </Col>
            </Row>
            <div
              className="p-lg-4"
              style={{
                border: `2px solid ${colors.primary.main}`,
                borderRadius: "0.6rem"
              }}
            >
              <Col>
                <h3>Purpose of this Statement</h3>
                <p>
                  This statement describes how we collect and utilize your
                  personal data in accordance with the General Data Protection
                  Regulations (GDPR), the Data Protection Jersey Law 2018, and
                  other relevant national laws and regulations in Jersey ("Data
                  Protection Legislation"). Please carefully review the
                  following to understand our practices regarding your personal
                  data and its treatment.
                </p>
              </Col>
              <Col>
                <h3>About Nimble IT Consulting</h3>
                <p>
                  In this data privacy statement, "Nimble IT Consulting" refers to a
                  group of companies based in Jersey, offering accountancy,
                  taxation, and secretarial services. Specifically, Nimble IT Consulting
                  Limited provides accountancy and taxation services, while
                  Nimble IT Consulting Secretaries Limited, regulated by the Jersey
                  Financial Services Commission, handles company secretarial
                  services. As the data controller, we determine how personal
                  data is held and used. According to GDPR requirements, we are
                  obligated to inform you about the details outlined in this
                  statement.
                  <br />
                </p>
                <p>
                  {" "}
                  We have designated an Information Protection Officer as our
                  Data Protection Point of Contact to assist with any inquiries
                  regarding this statement or the handling of your personal
                  data. To contact our Data Protection Point of Contact, please
                  refer to the details provided in paragraph 12 (Contact Us)
                  below.
                </p>
              </Col>
              <Col>
                <h3>Collection of Your Personal Data</h3>
                <p>
                  We gather personal data about you in various ways, including
                  when:
                </p>
                <ul>
                  {personalData.map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              </Col>
              <Col>
                <h3>Types of Information We Hold About You</h3>
                <p>The information we may hold about you includes:</p>
                <ul>
                  {typesOfInformation.map((item, i) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </Col>
              <Col>
                <h3>Use of Your Personal Data</h3>
                <p>
                  We may process your personal data for purposes necessary to
                  fulfill our contract with you, your employer, or our clients,
                  and to comply with legal obligations. This may include
                  processing personal data related to our legitimate interests,
                  provided they do not override your interests, rights, and
                  freedoms requiring personal data protection. Additionally, we
                  may process personal data for specific purposes with your
                  consent, where applicable.
                </p>
                <p>
                  Please note that depending on the specific purpose, we may
                  process your personal data under more than one lawful basis.
                </p>
                <p>Situations in which we use your personal data include:</p>
                <ul>
                  {yourData.map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              </Col>
              <Col>
                <h3>Data Retention</h3>
                <p>
                  We retain personal data only for as long as necessary to
                  fulfill the purposes for which it was collected. When
                  determining the appropriate retention period, we consider
                  various factors, including business requirements, legal
                  obligations, and the nature of the data.
                </p>
              </Col>
              <Col>
                <h3>Change of Purpose</h3>
                <p>
                  If we need to use your personal data for a purpose other than
                  that for which it was originally collected, we will only do so
                  if that purpose is compatible with the original one. Should a
                  new purpose arise, we will notify you and explain the legal
                  basis for such processing before commencing any new
                  activities.
                </p>
              </Col>
              <Col>
                <h3>Sharing Your Personal Data</h3>
                <p>
                  We share personal data with third parties where required by
                  law, necessary to manage our relationship with you, or when we
                  have a legitimate interest. Our third-party service providers
                  are bound by agreements to process personal data only as
                  instructed and to implement appropriate security measures.
                </p>
                <p>
                  Additionally, we may share personal data with other third
                  parties in the context of business sale or restructuring, or
                  to comply with legal obligations.
                </p>
              </Col>
              <Col>
                <h3>
                  Transfer of Personal Data Outside the European Economic Area
                  (EEA)
                </h3>
                <p>
                  We transfer personal data collected about you outside the EEA
                  only to fulfill our contract with you and according to your
                  instructions regarding data transfer. If personal data is
                  transferred to the USA, it's typically due to the use of Cloud
                  software. We ensure such transfers comply with applicable
                  regulations and adopt appropriate security measures.
                </p>
              </Col>
              <Col>
                <h3>Data Security</h3>
                <p>
                  We implement commercially reasonable security measures to
                  prevent unauthorized access, use, alteration, or disclosure of
                  personal data. Access to personal data is restricted to
                  authorized personnel who require it for business purposes. We
                  have procedures in place to address suspected data breaches
                  and will notify you and relevant regulators as required by
                  law.
                </p>
              </Col>
              <Col>
                <h3>Your Rights Regarding Personal Data</h3>
                <p>
                  To ensure the accuracy and currency of your personal data,
                  please inform us of any changes. Under certain circumstances,
                  you have the right to access, correct, erase, or restrict the
                  processing of your personal data. If you wish to exercise
                  these rights, please contact our Data Protection Point of
                  Contact.
                </p>
              </Col>
              <Col>
                <h3>Right to Withdraw Consent</h3>
                <p>
                  If you provided consent for specific processing purposes, you
                  have the right to withdraw it at any time. Once we receive
                  your withdrawal notification, we will cease processing your
                  personal data for the specified purpose unless another legal
                  basis exists.
                </p>
              </Col>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
