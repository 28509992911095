import { colors } from "assets/colors";
import React from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionSharing() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="section"
        style={{ backgroundColor: colors.primary.main }}
        id="demoPay"
      >
        <Container>
          <Row style={{ width: "100%", alignItems: "center" }}>
            <Col lg={8} md={8} xs={12}>
              <div style={{ width: "100%" }}>
                <h3
                  className="title"
                  style={{ textTransform: "uppercase", fontWeight: "500" }}
                >
                  Any solution you want for your business?
                </h3>
                <h3
                  className="title"
                  style={{ fontWeight: "600", textTransform: "capitalize" }}
                >
                  Connect with us
                </h3>
              </div>
            </Col>
            <Col lg={3} md={4} xs={6}>
              <Button
                color={colors.primary.main}
                onClick={() => {
                  navigate("/contact-us");
                }}
                style={{
                  backgroundColor: colors.primary.secondary,
                  color: "#ffff",
                  padding: "1rem",
                  width: "100%",
                  border: "none",
                  fontSize: "0.8rem",
                  fontWeight: "600"
                }}
                target="_blank"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    </>
  );
}

export default SectionSharing;
