import { colors } from "assets/colors";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import homeOffice from "assets/img/homeOffice.webp";
import accenture from "assets/img/Accenture-Logo.webp";
import capgimini from "assets/img/capgemini.webp";
import deloitte from "assets/img/Deloitte-Logo.webp";
import baySystems from "assets/img/BAE_Systems.webp";
import amber from "assets/img/amber.webp";
import boldyn from "assets/img/bolden.png";
import cabinetOffice from "assets/img/cabinet_office.png";

const SectionCompanies = () => {
  const companies = [
    homeOffice,
    accenture,
    capgimini,
    deloitte,
    baySystems,
    amber,
    boldyn,
    cabinetOffice
  ];
  return (
    <>
      <div className="section" id="demoPay" style={{ textAlign: "center" }}>
        <h2
          style={{
            fontWeight: "600",
            marginTop: "0.7rem",
            marginBottom: "0.5rem",
            color: colors.primary.secondary
          }}
        >
          Our Valuable Clients
        </h2>
        <Container>
          <Row
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexWrap: "wrap",
              marginLeft: "0px"
            }}
          >
            {companies.map((item, index) => {
              return (
                <Col
                  lg={2}
                  md={4}
                  sm={5}
                  xs={6}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <div
                    style={{
                      border: `1px solid ${colors.primary.main}`,
                      borderRadius: "0.3rem",
                      padding: "0.2rem",
                      width: "200px",
                      marginTop: "0.5rem"
                    }}
                  >
                    <img
                      src={item}
                      alt={index}
                      style={{
                        objectFit:"contain",
                        width: "130px",
                        height: "130px"
                      }}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SectionCompanies;
