/*eslint-disable*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Container } from "reactstrap";
import Background from "assets/img/background.jpg";
// core comments

function PresentationHeader() {
  const { innerWidth } = window;
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [windowWidth]);
  return (
    <>
      <div className="wrapper">
        <div
          className="page-header"
          style={{ marginTop: "5rem", height: "100%", alignItems: "center" }}
        >
          <div className="filter filter-primary" />
          <div className="video-background">
            <video autoPlay loop muted>
              <source
                src="https://video.wixstatic.com/video/11062b_6743da5900054f1f8e69f53302930a6a/1080p/mp4/file.mp4"
                type="video/mp4"
              />
            </video>
            <div className="content">
              <div className="title-brand" style={{ marginTop: "250px" }}>
                <h1
                  style={{
                    fontSize: windowWidth < 551 ? "3rem" : "4rem",
                    fontWeight: "bolder"
                  }}
                >
                  Nimble IT Consulting
                </h1>
                <label style={{ fontSize: "2rem" }}>
                  Ready for the Digital Age
                </label>
              </div>
            </div>
          </div>

          {/* <div
            className="content-center header-background"
            style={{ height: "100%", backgroundColor: "blue" }}
          >
            <Container
              style={{
                textAlign: "center",
                marginTop: "15rem"
              }}
            >
              <div className="title-brand">
                <h1
                  className="presentation-title"
                  style={{ fontSize: windowWidth < 551 ? "3rem" : "auto" }}
                >
                  Nimble IT Consulting
                </h1>
              </div>
              <h2 className="presentation-subtitle text-center">
                Make your mark with a premium Bootstrap 4, React, React Hooks
                and Reactstrap UI Kit!
              </h2>
            </Container>
          </div> */}
          {/* <h6 className="category category-absolute">
            Designed and coded by{" "}
            <a
              href="https://www.creative-tim.com?ref=pkpr-presentation-header"
              target="_blank"
            >
              <img
                alt="..."
                className="creative-tim-logo"
                src={require("assets/img/creative-tim-white-slim2.png")}
              />
            </a>
          </h6> */}
        </div>
      </div>
    </>
  );
}

export default PresentationHeader;
