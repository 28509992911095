/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/img/Bizsolution.jpeg";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
import { colors } from "assets/colors";
import NewTwitter from "assets/img/newTwitter.png";

// core components

function FooterBlack() {
  const { innerWidth } = window;
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  const Icons = [
    {
      icon: "fa-brands fa-twitter",
      image: NewTwitter,
      name: "Twitter",
      id: "twitter",
      redirect: "https://twitter.com/?lang=en"
    },
    {
      icon: "fa-facebook-square",
      name: "Facebook",
      id: "facebook",
      redirect: "https://www.facebook.com/"
    },
    {
      icon: "fa-brands fa-linkedin",
      name: "Linkedin",
      id: "linkedin",
      redirect: "https://www.linkedin.com/company/nimble-it-consulting/about/"
    },
    // {
    //   icon: "fa-whatsapp",
    //   name: "Whatsapp",
    //   id: "whatsapp"
    // }
  ];
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [windowWidth]);
  return (
    <section
      className="main-footer pl-4 pr-4"
      style={{ overflowX: "hidden", backgroundColor: colors.primary.secondary }}
    >
      <Row
        className="mt-3 mb-3"
        style={{ paddingLeft: windowWidth > 1002 && "1.5rem" }}
      >
        <Col lg={8} xs={12}>
          <div>
            <h2
              style={{
                fontSize: "3rem",
                color: colors.primary.main,
                fontWeight: "600"
              }}
            >
              Nimble IT Consulting
            </h2>
            <p style={{ fontSize: "1rem", color: "#ffff" }}>
              Ready for the Digital Age
            </p>
            <p style={{ fontSize: "0.8rem", color: "#ffff" }}>
              info@nimbleitconsulting.com
            </p>
          </div>
        </Col>
        <Col
          lg={1}
          md={2}
          xs={5}
          sm={3}
          style={{ marginRight: windowWidth < 978 ? "0.5rem" : "2rem" }}
        >
          {" "}
          <div>
            <h2
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                color: "#ffff",
                marginBottom: "0.5rem"
              }}
            >
              Social
            </h2>

            <p
              style={{
                fontSize: "0.65rem",
                cursor: "pointer",
                color: "#EFEFE9"
              }}
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/company/nimble-it-consulting/about/";
              }}
            >
              LinkedIn
            </p>

            <p
              style={{
                fontSize: "0.65rem",
                cursor: "pointer",
                color: "#EFEFE9"
              }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact
            </p>
          </div>
        </Col>
        <Col
          lg={1}
          md={2}
          xs={5}
          sm={3}
          style={{ marginRight: windowWidth < 978 ? "0.5rem" : "2rem" }}
        >
          <div>
            <h2
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                color: "#ffff",
                marginBottom: "0.5rem"
              }}
            >
              Legal
            </h2>
            <p
              style={{
                fontSize: "0.65rem",
                cursor: "pointer",
                color: "#EFEFE9"
              }}
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy
            </p>
            {/* <p
              style={{
                fontSize: "0.65rem",
                cursor: "pointer",
                color: "#EFEFE9"
              }}
              onClick={() => {
                window.location.href =
                  "https://www.diligentpharma.com/cookies/";
              }}
            >
              Cookies
            </p> */}
          </div>
        </Col>
      </Row>
      <Row
        style={{
          paddingLeft: windowWidth > 1002 && "1.5rem",
          paddingBottom: "2rem"
        }}
      >
        <Col lg={8} md={7} xs={12}>
          <p style={{ fontSize: "0.8rem", color: "#ffff" }}>
            Telephone No: 0044 743 853 4707
          </p>
          <p style={{ fontSize: "0.75rem", color: "#ffff" }}>
            © {new Date().getFullYear()} Nimble IT Consulting. All rights
            reserved.
          </p>
        </Col>
        <Col
          lg={4}
          md={4}
          style={{
            marginLeft:
              windowWidth < 1270 ? "1rem" : windowWidth < 768 ? "0px" : "-9rem"
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {Icons.map((item, index) => {
              return (
                <div>
                  <Button
                    // className="btn-round mr-1"
                    color={item.id}
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      borderRadius: "8rem",
                      width: "45px",
                      height: "45px",
                      display: "flex",
                    }}
                    onClick={() => {
                      window.location.href = item.redirect;
                    }}
                    id={item.id}
                  >
                    {item.image ? (
                      <img src={item.image} style={{ width: "1.5rem", height: "1.5rem" ,marginLeft: "-0.5rem", objectFit: "contain" }}/>
                    ) : (
                      <i
                        className={`fa ${item.icon}`}
                        style={{ fontSize: "1.5rem", marginLeft: "-0.5rem" }}
                      />
                    )}

                    {/* {item.name} */}
                  </Button>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default FooterBlack;
