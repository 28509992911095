import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import NimbleLogo from "assets/img/NimbleLogo.jpeg";
import { Navigate } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import { colors } from "assets/colors";
// core components

function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const navigate = useNavigate();

  const navItems = [
    {
      name: "Home",
      link: "/"
    },
    {
      name: "Career",
      link: "/career"
    }
  ];
  const { innerWidth } = window;
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [windowWidth]);
  console.log(windowWidth);
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className="fixed-top"
        expand="lg"
        id="navbar-main"
        style={{ padding: "0.5rem", backgroundColor: colors.primary.secondary }}
      >
        <Container
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            maxWidth: "100%"
          }}
        >
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              style={{ padding: "0px" }}
              to="/index"
              tag={Link}
            >
              <img
                src={NimbleLogo}
                alt="NimbleLogo"
                style={{
                  objectFit: "contain",
                  width: "180px"
                }}
              />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              Nimble IT Consulting
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav
              className="ml-auto"
              navbar
              style={{
                alignItems: "center",
                fontSize: "1rem",
                fontWeight: "500",
                color: colors.primary.secondary
              }}
            >
              {navItems.map((item, index) => {
                return (
                  <NavItem
                    className="mr-4"
                    caret
                    nav
                    key={item.name}
                    style={{
                      cursor: "pointer",
                      color: "white",
                      width: windowWidth < 992 &&"8rem",
                      borderRadius: "0.4rem",
                      textAlign: "center",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      marginLeft: windowWidth < 992 && "0.7rem",
                      textTransform: "uppercase",
                      marginBottom:
                        index === 0 && windowWidth < 992 ? "0.4rem" : "0px",
                      padding: windowWidth < 992 ? "0.5rem" : "auto",
                      backgroundColor: windowWidth < 992 && colors.primary.main
                    }}
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    {item.name}
                  </NavItem>
                );
              })}
              <NavItem>
                <Button
                  color={colors.primary.main}
                  style={{
                    backgroundColor: colors.primary.main,
                    border: "none",
                    fontSize: "0.8rem",
                    fontWeight: "600"
                  }}
                  target="_blank"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Get in Touch
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
