import { colors } from "assets/colors";
import React from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

function SectionContent() {
  const navigate = useNavigate();
  return (
    <>
      <div id="fadeInAnim">
        <div
          className="section section-content section-white"
          style={{ marginBottom: "-2rem", marginTop: "-2rem" }}
        >
          <div>
            <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
              <Col md="6">
                <div className="content mb-3">
                  <p
                    style={{
                      color: "black",
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      paddingLeft: "0.5rem",
                      width: "80%"
                    }}
                    className="mb-3"
                  >
                    <h3
                      className="title"
                      style={{ fontSize: "3rem", fontWeight: "600" }}
                    >
                      Excel your business
                      <br />
                      with skilled
                      <br /> talent
                    </h3>
                    Empower your business with the expertise it deserves. From
                    DevOps mastery to cloud solutions brilliance, digital
                    transformation wizardry, and web development finesse – we
                    provide skilled professionals to fuel your success story.
                    Let's shape the future together.
                  </p>
                  <Button
                    color={colors.primary.main}
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                    style={{
                      backgroundColor: colors.primary.main,
                      color: "#ffff",
                      padding: "1rem",
                      width: "60%",
                      marginLeft: "0.3rem",
                      border: "none",
                      fontSize: "0.8rem",
                      fontWeight: "600"
                    }}
                    target="_blank"
                  >
                    Get Started Today!
                  </Button>
                </div>
              </Col>
              <Col
                md="5"
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img
                  alt="..."
                  className="add-animation"
                  style={{
                    borderRadius: "0.8rem",
                    width: "100%",
                    objectFit: "fill"
                  }}
                  src={require("assets/img/skilledPeople.jpeg")}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
