import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponents() {
  return (
    <>
      <div className="section section-components section-dark">
        <Row style={{display: "flex" , justifyContent:"center"}}>
          <Col lg="7" md="12" xs={12}>
            <div className="image-container">
              {/* <img
                alt="..."
                className="components-macbook"
                src={require("assets/img/agaile.png")}
              /> */}
              <img
                alt="..."
                className="components-macbook"
                style={{borderRadius: "0.8rem"}}
                src={require("assets/img/img1_1.jpeg")}
              />
              <img
                alt="..."
                className="table-img"
                src={require("assets/img/agaile.png")}
              />
              <img
                alt="..."
                className="share-btn-img"
                src={require("assets/img/devOps.png")}
              />
              <img
                alt="..."
                className="coloured-card-btn-img"
                src={require("assets/img/scrum-values.png")}
              />
              <img
                alt="..."
                className="coloured-card-img"
                src={require("assets/img/cloudSolution.png")}
              />
              <img
                alt="..."
                className="social-img"
                src={require("assets/img/webdevelopment.webp")}
              />
              <img
                alt="..."
                className="pin-btn-img"
                src={require("assets/img/webdevelopment.webp")}
              />
            </div>
          </Col>
          <Col className="mr-auto" lg="4" md="10" >
            <Container className="basic-container">
              <h3 className="title">Our Business</h3>
              <h5 className="description">
                We are what our name is “Nimble”, we strive to be at the
                forefront in this era of digital disruption by being dynamic,
                agile and cohesive in providing your business the solutions
                needed to leverage it to the next level. Our expert team of
                Engineers, Programmers, Designers and Business development
                professionals are the foundations of our firm with the fusion of
                cutting-edge technology.
              </h5>
              <h5 className="description">
                Nimble IT Consulting is vested in Research and Analysis of
                Current and Upcoming trends, be it Technology, Business Values
                and User Experience, we dedicate our efforts tirelessly to be at
                the pinnacle of the Quality Standards. Devising solutions that
                are just not only being approved or followed by industry leaders
                in fact they depend on it. Connect with us to learn more of our
                solution offerings.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
