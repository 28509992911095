import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterAboutUs from "components/Footers/FooterAboutUs.js";
import AccountingHeader from "components/Headers/AboutUsHeader";
import FooterBlack from "components/Footers/FooterBlack";
import CustomCard from "components/custom-cards";
function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  const accountData = [
    {
      title: "Streamlined Accounts Preparation",
      description: "Achieve peace of mind with our clear and precise reporting. Our expert team identifies and improves potential inefficiencies, ensuring accurate financial documentation and celebrating your successes with confidence."
    },
    {
      title: "Professional Outsourced Accountancy Support",
      description: "When your business needs extra support, we're here to help. Whether you're a solo entrepreneur or a large corporation, entrust us with your accounts preparation and ad-hoc projects, freeing you to focus on your core business activities."
    },
    {
      title: "Strategic Management Accounts",
      description: "Make informed decisions with our accurate performance reporting. Gain a clear understanding of your business's position and receive the tools needed to make strategic decisions throughout the year."
    },
    {
      title: "Assurance Services",
      description: "Tailored to your unique business needs, our Assurance team conducts professional assessments to provide valuable feedback and suggestions for improving internal control procedures, ensuring efficiency and compliance."
    },
    {
      title: "Specialized Forensic Accounting",
      description: "In complex financial situations, rely on our expertise. Whether it's business, matrimonial, financial, or fiscal investigations, our Forensic Accounting team provides support and equips you with the tools needed for resilience and stability."
    }
  ];
  
  const businessServices = [
    {
      title: "Financial Record Keeping",
      description:
        "Maintain precision with our comprehensive record-keeping services. Our adept team is well-versed in legislation and ethical obligations, utilizing both traditional and cloud-based systems to provide accurate accounting ledgers."
    },
    {
      title: "Business Launch Guidance",
      description:
        "Embarking on a new business venture is thrilling yet daunting. Let us guide you through the process, ensuring solid foundations for resilience and success. We'll handle the setup, equipping your new business for a successful launch."
    },
    {
      title: "Administrative Support",
      description:
        "Streamline your operations with our administrative services. From managing GST returns and supplier bills to preparing insightful reports, we lighten your workload, allowing you to focus on core business activities."
    },
    {
      title: "Business Assessment and Appraisal",
      description:
        "Whether buying or selling a business, make informed decisions with our comprehensive valuation services. With years of experience across diverse sectors, we provide accurate assessments and invaluable insights to empower confident negotiations."
    },
    {
      title: "Payroll Management",
      description:
        "Simplify payroll reporting with our comprehensive services. From running payroll to submitting documentation to relevant authorities, we handle all aspects, alleviating the stress and ensuring compliance."
    },
    {
      title: "IT and Accounting Software Assistance",
      description:
        "Stay ahead with our tailored IT and accounting software support. Our team is proficient in leading platforms like QuickBooks, Xero, and Sage, as well as custom software solutions. We ensure seamless integration with your business systems, delivering efficiency and compliance."
    }
  ];

  return (
    <>
      <ColorNavbar />
      <AccountingHeader />
      <div className="main" style={{ color: "#000" }}>
        <div className="section">
          <Container style={{paddingRight: "0px" , paddingLeft: "0px" ,     maxWidth: "1200px"}}>
            <h3
              className="title-uppercase text-center"
              style={{ fontWeight: "600" }}
            >
              Comprehensive Financial Solutions
            </h3>
            <div className="mt-4">
              <Row>
                {" "}
                {accountData.map((item) => {
                  return (
                    <CustomCard
                      key={item.name}
                      title={item.title}
                      description={item.description}
                    />
                  );
                })}
              </Row>
            </div>

            <h3
              className="title-uppercase text-center"
              style={{ fontWeight: "600" }}
            >
              BUSINESS SPECIFICS
            </h3>
            <div className="mt-4">
              <Row>
                {" "}
                {businessServices.map((item) => {
                  return (
                    <CustomCard
                      key={item.name}
                      title={item.title}
                      description={item.description}
                    />
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default AboutUs;
