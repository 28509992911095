import { colors } from "assets/colors";
import axios from "axios";
import ColorNavbar from "components/Navbars/ColorNavbar";
import { INTRODUCE } from "constant";
import { CONTACT_US } from "constant";
import { API } from "constant";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, Container, Form, Input, Row } from "reactstrap";
import CustomToast from "components/custom-toast";

const Career = () => {
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    country: "United Kingdom",
    email: "",
    phoneNo: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toast, setToast] = useState({
    message: "",
    open: false,
    type: "error"
  });
  const InputFields = [
    {
      name: "firstName",
      label: "First Name *",
      type: "string",
      md: 12
    },
    {
      name: "lastName",
      label: "Last Name *",
      type: "string",
      md: 12
    },
    {
      name: "country",
      label: "Country",
      type: "string",
      disabled: true,
      md: 12
    },
    {
      name: "email",
      label: "Email *",
      type: "email",
      md: 12
    },
    {
      name: "phoneNo",
      label: "Phone No *",
      type: "string",
      md: 12
    }
  ];
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      !fields.firstName ||
      !fields.lastName ||
      !fields.phoneNo ||
      !fields.email
    ) {
      setError("Please complete all required fields");
    } else {
      setIsLoading(true);
      setError("");
      if (!/\S+@\S+\.\S+/.test(fields.email)) {
        return setError("Please enter a valid email address");
      } else {
        setError("");
        const body = {
          ...fields,
          subject: `Introduction of ${fields.firstName}`
        };
        try {
          const response = await axios.post(`${API}${INTRODUCE}`, body);
          if (response) {
            setFields({
              firstName: "",
              lastName: "",
              country: "United Kingdom",
              email: "",
              phoneNo: ""
            });
            setToast({
              open: true,
              type: "success",
              message: response.data.title
            });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };
  return (
    <>
      <ColorNavbar />
      <div className="main">
        <CustomToast />
        <div className="section section-white mt-3">
          <Container style={{ color: colors.primary.secondary }}>
            <h2 style={{ fontWeight: "600" }}>Introduce Yourself</h2>
            <p
              className="pb-6 mt-3"
              style={{ fontWeight: "500", fontSize: "1rem", maxWidth: "600px" }}
            >
              Ready to join a dynamic team at Nimble IT Consulting? Share your
              unique skills and experiences with us – we're excited to learn
              more about what makes you a perfect fit for our innovative
              projects!
            </p>

            {error && (
              <p style={{ fontWeight: "600", color: "red" }}>{error}</p>
            )}
            <Row
              style={{
                marginTop: "2.5rem",
                border: `1px solid ${colors.primary.main}`,
                borderRadius: "0.8rem",
                display: "flex",
                justifyContent: "left",
                textAlign: "left",
                // backgroundColor: "black",
                padding: "2rem"
              }}
            >
              <Col className="text-center" md="6">
                <Form className="contact">
                  <Row>
                    {InputFields.map((item) => {
                      return (
                        <Col md={item.md} style={{ padding: "0.8rem" }}>
                          <Input
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                [item.name]: e.target.value
                              });
                            }}
                            disabled={item.disabled}
                            placeholder={item.label}
                            value={fields[item.name]}
                            type={item.type}
                            rows={item.rows}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className="mt-3">
                    <Col md="6" sm="5" xs="7" className="p-0 ml-3">
                      <Button
                        block
                        disabled={isLoading}
                        onClick={onSubmitHandler}
                        style={{
                          backgroundColor: colors.primary.main,
                          border: "none",
                          width: "100%"
                        }}
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <CustomToast
        open={toast.open}
        message={toast.message}
        type={toast.type}
        setOpen={setToast}
      />
    </>
  );
};

export default Career;
