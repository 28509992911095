import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CustomToast = ({ open, message, type, setOpen }) => {
  const onCloseToast = () => {
    setOpen({
      message: "",
      open: false,
      type: "error"
    });
  };
  const notify = () => {
    if (type === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        onClose: onCloseToast()
      });
    } else if (type === "success") {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        onClose: onCloseToast()
      });
    } else {
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        onClose: onCloseToast()
      });
    }
  };
  useEffect(() => {
    if (open) {
      notify();
    }
  }, [open]);
  return <ToastContainer />;
};

export default CustomToast;
